#root * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#root .main-banner-wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-color: #f3f2f7;
}

#root .main-banner-wrap .main-banner-container .main-banner-list-header .sort-image-preview {
  -webkit-box-flex: 1.5;
      -ms-flex: 1.5;
          flex: 1.5;
}

#root .main-banner-wrap .main-banner-container .main-banner-list-header .sort-image-link {
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
}

#root .main-banner-wrap .main-banner-container .main-banner-list-content {
  width: 100%;
  padding: 1.875vw 2.5vw;
  background-color: #fff;
  border-radius: 0.8333vw;
}

#root .main-banner-wrap .main-banner-container .main-banner-list-content li.main-banner-list-item ul li.image-preview {
  -webkit-box-flex: 1.5;
      -ms-flex: 1.5;
          flex: 1.5;
  height: 100%;
  padding: 0.4166vw;
}

#root .main-banner-wrap .main-banner-container .main-banner-list-content li.main-banner-list-item ul li.image-preview img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  background-color: #f5f5f5;
}

#root .main-banner-wrap .main-banner-container .main-banner-list-content li.main-banner-list-item ul li.image-link {
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
}

#root .main-banner-wrap .main-banner-container .main-banner-list-content li.main-banner-list-item ul li.exposure-access .exposure-box {
  position: relative;
  width: 1.354vw;
  height: 1.354vw;
  line-height: 1.354vw;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 0.20833vw;
}

#root .main-banner-wrap .main-banner-container .main-banner-list-content li.main-banner-list-item ul li.exposure-access .exposure-box::before {
  content: "O";
  width: 1.25vw;
  height: 1.25vw;
  line-height: 1.25vw;
  position: absolute;
  top: 0;
  left: 0;
}

#root .main-banner-wrap .main-banner-container .main-banner-list-content li.main-banner-list-item ul li.exposure-access .check-box.expired::before {
  content: "-";
}

#root .main-banner-wrap .main-banner-container .main-banner-list-content li.main-banner-list-item ul li.exposure-rank .rank-box {
  width: 1.354vw;
  height: 1.354vw;
  line-height: 1.354vw;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 0.20833vw;
}

#root .main-banner-wrap .main-banner-container .main-banner-list-content li.main-banner-list-item ul li.remove-button button {
  display: inline-block;
  width: 1.25vw;
  height: 1.25vw;
  border-radius: 0.20833vw;
  background-color: #ddd;
}

#root .main-banner-wrap .main-banner-container .main-banner-list-content li.main-banner-list-item ul li.remove-button button:active {
  background-color: #ddd;
}
