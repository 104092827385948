#root .report-wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-color: #f3f2f7;
}

#root .report-wrap .report-container .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#root .report-wrap .report-container .content .report-list {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
  margin-right: 2.604vw;
}

#root .report-wrap .report-container .details-wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-radius: 0.8333vw;
  position: relative;
}

#root .report-wrap .report-container .details-wrap .item-details {
  position: fixed;
  padding: 1.0416vw;
  border-radius: 0.8333vw;
  text-align: center;
  background-color: #fff;
}

#root .report-wrap .report-container .details-wrap .item-details .answer-button,
#root .report-wrap .report-container .details-wrap .item-details .item-image-button {
  width: 8.333vw;
  height: 1.666vw;
  line-height: 0.2083vw;
  padding: 0.2083vw;
  border: none;
  border-radius: 0.3125vw;
  background-color: #00b074;
  color: #fff;
  font-size: 0.833vw;
}

#root .report-wrap .report-container .details-wrap .item-details .option {
  padding: 0.4166vw;
  margin: 0 auto 0.5208vw;
}

#root .report-wrap .report-container .details-wrap .item-details .option label {
  font-weight: normal;
  display: block;
  margin-bottom: 0.8332vw;
  font-size: 0.9375vw;
}

#root .report-wrap .report-container .details-wrap .item-details .option .item-question,
#root .report-wrap .report-container .details-wrap .item-details .option .item-detail-content {
  font-size: 0.833vw;
  display: block;
  margin: 0 auto;
  width: 240px;
  line-height: 1.8vw;
  height: 1.8vw;
  border: 1px solid #ddd;
  border-radius: 5px;
}

#root .report-answer {
  position: absolute;
  width: 500px;
  height: 400px;
  padding: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: lightblue;
  text-align: center;
}

#root .report-answer .report-content {
  height: 10.4166vw;
}

#root .report-image {
  position: absolute;
  padding: 1.0416vw;
  background-color: lightblue;
}

#root .report-image .image-area img {
  width: 500px;
  height: 400px;
  -o-object-fit: contain;
     object-fit: contain;
}
