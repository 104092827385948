#root {
  * {
    box-sizing: border-box;
  }
  .main-banner-edit-wrap {
    flex: 1;
    min-height: 100%;
    background-color: #f3f2f7;
  }
}
