#root * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#root .main-banner-edit-wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-height: 100%;
  background-color: #f3f2f7;
}
