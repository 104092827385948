#root {
  * {
    box-sizing: border-box;
  }
  .main-banner-create-wrap {
    flex: 1;
    min-height: 100%;
    background-color: #f3f2f7;

    .main-banner-create-container {
      .main-banner-create-content {
        width: 100%;
        height: 28.125vw;
        padding: 3.125vw 2.5vw;
        background-color: #fff;
        border-radius: 0.8333vw;
        display: flex;

        .content-left {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          .attach {
            height: 3.125vw;
            display: flex;
            align-items: center;

            label.title {
              font-size: 0.8333vw;
              font-weight: 700;
              width: 4.166vw;
            }
            input[type="text"],
            input[type="number"] {
              width: 21.77vw;
              height: 2.3vw;
              padding-left: 12px;
              margin: 0 1.04vw 0 0;
              border: none;
              border-bottom: 1px solid #e4e4e4;
            }

            input[type="file"] + label {
              display: inline-block;
              border-radius: 0.4166vw;
              width: 5.208vw;
              height: 2.604vw;
              font-size: 0.8333vw;
              background-color: #00b074;
              color: #fff;
              line-height: 2.604vw;
              text-align: center;
              cursor: pointer;
            }
            input[type="datetime-local"] {
              display: inline-block;
              max-width: 11.2vw;
            }
            label.inner {
              margin-left: 1.04166vw;
            }
          }
        }
        .content-right {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-weight: 700;
          }
          .attached-image-preview {
            width: 100%;
            height: 19.8vw;
            background-color: #ddd;

            img.preview-image {
              width: 100%;
              height: 19.8vw;
              object-fit: contain;
              border: 1px solid #ddd;
            }
          }
        }
      }
    }
  }
}
