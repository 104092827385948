#root .popup-wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-color: #f3f2f7;
}

#root .popup-wrap .popup-container .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#root .popup-wrap .popup-container .content .popup-list {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
  margin-right: 2.604vw;
}

#root .popup-wrap .popup-container .content .popup-list-item-details-wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-radius: 0.8333vw;
  position: relative;
}

#root .popup-wrap .popup-container .content .popup-list-item-details-wrap .popup-list-item-details {
  position: fixed;
  padding: 1.0416vw;
  border-radius: 0.8333vw;
  text-align: center;
  background-color: #fff;
}

#root .popup-wrap .popup-container .content .popup-list-item-details-wrap .popup-list-item-details .delete-button,
#root .popup-wrap .popup-container .content .popup-list-item-details-wrap .popup-list-item-details .show-image-button {
  width: 8.333vw;
  height: 1.666vw;
  line-height: 0.2083vw;
  padding: 0.2083vw;
  border: none;
  border-radius: 0.3125vw;
  background-color: #00b074;
  color: #fff;
  font-size: 0.833vw;
}

#root .popup-wrap .popup-container .content .popup-list-item-details-wrap .popup-list-item-details .option {
  padding: 0.4166vw;
  margin: 0 auto 0.5208vw;
}

#root .popup-wrap .popup-container .content .popup-list-item-details-wrap .popup-list-item-details .option label {
  font-weight: normal;
  display: block;
  margin-bottom: 0.8332vw;
  font-size: 0.9375vw;
}

#root .popup-wrap .popup-container .content .popup-list-item-details-wrap .popup-list-item-details .option .item-title,
#root .popup-wrap .popup-container .content .popup-list-item-details-wrap .popup-list-item-details .option .reference-url,
#root .popup-wrap .popup-container .content .popup-list-item-details-wrap .popup-list-item-details .option .date {
  font-size: 0.833vw;
  display: block;
  margin: 0 auto;
  width: 240px;
  line-height: 1.8vw;
  height: 1.8vw;
  border: 1px solid #ddd;
  border-radius: 5px;
}

#root .popup-wrap .popup-container .content .popup-list-item-details-wrap .popup-list-item-details .option .reference-url a {
  text-decoration: underline;
}

#root .popup-wrap .popup-container .content .popup-list-item-details-wrap .popup-list-item-details .option .click-number {
  font-size: 0.833vw;
}

#root .popup-image-wrap {
  width: 25vw;
  height: 25vw;
  background-color: #fff;
  position: relative;
  border-radius: 0.3vw;
  overflow: hidden;
}

#root .popup-image-wrap .button-box {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
}

#root .popup-image-wrap .button-box .close-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.4rem 0.8rem;
  border-radius: 0.4166vw;
}

#root .popup-image-wrap .image-box {
  width: 25vw;
  height: 25vw;
}

#root .popup-image-wrap .image-box .popup-image {
  width: 25vw;
  height: 25vw;
  -o-object-fit: contain;
     object-fit: contain;
}

#root .cancle-popup-wrap {
  width: 25vw;
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.5rem 1rem;
  position: relative;
  text-align: center;
}

#root .cancle-popup-wrap .button-box {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

#root .cancle-popup-wrap .content {
  line-height: 2;
  font-size: 10.4166vw;
  margin-bottom: 1rem;
}

#root .cancle-popup-wrap .bottom-button-box button {
  background-color: #eee;
  padding: 0.8rem 1rem;
  border-radius: 0.8rem;
}
