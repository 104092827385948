#root * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#root .main-banner-create-wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-height: 100%;
  background-color: #f3f2f7;
}

#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content {
  width: 100%;
  height: 28.125vw;
  padding: 3.125vw 2.5vw;
  background-color: #fff;
  border-radius: 0.8333vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content .content-left {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content .content-left .attach {
  height: 3.125vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content .content-left .attach label.title {
  font-size: 0.8333vw;
  font-weight: 700;
  width: 4.166vw;
}

#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content .content-left .attach input[type="text"],
#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content .content-left .attach input[type="number"] {
  width: 21.77vw;
  height: 2.3vw;
  padding-left: 12px;
  margin: 0 1.04vw 0 0;
  border: none;
  border-bottom: 1px solid #e4e4e4;
}

#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content .content-left .attach input[type="file"] + label {
  display: inline-block;
  border-radius: 0.4166vw;
  width: 5.208vw;
  height: 2.604vw;
  font-size: 0.8333vw;
  background-color: #00b074;
  color: #fff;
  line-height: 2.604vw;
  text-align: center;
  cursor: pointer;
}

#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content .content-left .attach input[type="datetime-local"] {
  display: inline-block;
  max-width: 11.2vw;
}

#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content .content-left .attach label.inner {
  margin-left: 1.04166vw;
}

#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content .content-right {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content .content-right .title {
  font-weight: 700;
}

#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content .content-right .attached-image-preview {
  width: 100%;
  height: 19.8vw;
  background-color: #ddd;
}

#root .main-banner-create-wrap .main-banner-create-container .main-banner-create-content .content-right .attached-image-preview img.preview-image {
  width: 100%;
  height: 19.8vw;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid #ddd;
}
