#root .login-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#root .login-wrap .login-container {
  width: 50%;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 1.666vw;
  padding: 6vw 0;
}

#root .login-wrap .login-container h1 {
  text-align: center;
  font-size: 2vw;
  margin-bottom: 2.5vw;
}

#root .login-wrap .login-container .login-input {
  display: block;
  width: 21.0416vw;
  height: 2.2083vw;
  padding: 0.3083vw;
  margin: 0.5208vw auto;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.3083vw;
}

#root .login-wrap .login-container .login-btn {
  display: block;
  margin: 2vw auto;
  width: 8.33vw;
  height: 36px;
  padding: 0.2083vw;
  border: none;
  border-radius: 5px;
  background-color: lightblue;
  color: #fff;
}
