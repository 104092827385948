#root {
  * {
    box-sizing: border-box;
  }
  .main-banner-wrap {
    flex: 1;
    background-color: #f3f2f7;

    .main-banner-container {
      .main-banner-list-header {
        .sort-image-preview {
          flex: 1.5;
        }
        .sort-image-link {
          flex: 3;
        }
      }

      .main-banner-list-content {
        width: 100%;
        padding: 1.875vw 2.5vw;
        background-color: #fff;
        border-radius: 0.8333vw;

        li.main-banner-list-item {
          ul {
            li.image-preview {
              flex: 1.5;
              height: 100%;
              padding: 0.4166vw;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                background-color: #f5f5f5;
              }
            }

            li.image-link {
              flex: 3;
            }

            li.exposure-access {
              .exposure-box {
                position: relative;
                width: 1.354vw;
                height: 1.354vw;
                line-height: 1.354vw;
                margin: 0 auto;
                border: 1px solid #ddd;
                border-radius: 0.20833vw;

                &::before {
                  content: "O";
                  width: 1.25vw;
                  height: 1.25vw;
                  line-height: 1.25vw;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }

              .check-box.expired {
                &::before {
                  content: "-";
                }
              }
            }

            li.exposure-rank {
              .rank-box {
                width: 1.354vw;
                height: 1.354vw;
                line-height: 1.354vw;
                margin: 0 auto;
                border: 1px solid #ddd;
                border-radius: 0.20833vw;
              }
            }
            li.remove-button {
              button {
                display: inline-block;
                width: 1.25vw;
                height: 1.25vw;
                border-radius: 0.20833vw;
                background-color: #ddd;

                &:active {
                  background-color: #ddd;
                }
              }
            }
          }
        }
      }
    }
  }
}
