#root {
  .post-wrap {
    flex: 1;
    background-color: #f3f2f7;
  }
  .post-popup-wrap {
    width: fit-content;
    border-radius: 15px;
    padding: 1rem 2rem;
    background-color: #fff;
    position: relative;

    .button-box {
      display: flex;
      justify-content: flex-end;

      .close-button {
        width: fit-content;
        padding: 0.4rem 0.8rem;
        border-radius: 0.4166vw;
        background-color: #ddd;
      }
      .post-button {
        width: fit-content;
        padding: 0.5rem 1rem;
        border-radius: 0.4166vw;
        background-color: #ddd;
      }
    }
    .conform-content {
      font-size: 1.2rem;
      padding: 1rem 0;

      & > div {
        margin-bottom: 0.6rem;
      }

      a {
        text-decoration: underline;
        color: blue;
      }
    }
  }
}
