#root {
  .popup-wrap {
    flex: 1;
    background-color: #f3f2f7;

    .popup-container {
      .content {
        display: flex;
        .popup-list {
          flex: 2;
          margin-right: 2.604vw;
        }

        .popup-list-item-details-wrap {
          flex: 1;
          border-radius: 0.8333vw;
          position: relative;

          .popup-list-item-details {
            position: fixed;
            padding: 1.0416vw;
            border-radius: 0.8333vw;
            text-align: center;
            background-color: #fff;

            .delete-button,
            .show-image-button {
              width: 8.333vw;
              height: 1.666vw;
              line-height: 0.2083vw;
              padding: 0.2083vw;
              border: none;
              border-radius: 0.3125vw;
              background-color: #00b074;
              color: #fff;
              font-size: 0.833vw;
            }

            .option {
              padding: 0.4166vw;
              margin: 0 auto 0.5208vw;

              label {
                font-weight: normal;
                display: block;
                margin-bottom: 0.8332vw;
                font-size: 0.9375vw;
              }

              .item-title,
              .reference-url,
              .date {
                font-size: 0.833vw;
                display: block;
                margin: 0 auto;
                width: 240px;
                line-height: 1.8vw;
                height: 1.8vw;
                border: 1px solid #ddd;
                border-radius: 5px;
              }

              .reference-url a {
                text-decoration: underline;
              }
              .click-number {
                font-size: 0.833vw;
              }
            }
          }
        }
      }
    }
  }
  .popup-image-wrap {
    width: 25vw;
    height: 25vw;
    background-color: #fff;
    position: relative;
    border-radius: 0.3vw;
    overflow: hidden;

    .button-box {
      position: absolute;
      right: 1rem;
      top: 0.5rem;

      .close-button {
        width: fit-content;
        padding: 0.4rem 0.8rem;
        border-radius: 0.4166vw;
      }
    }

    .image-box {
      width: 25vw;
      height: 25vw;

      .popup-image {
        width: 25vw;
        height: 25vw;
        object-fit: contain;
      }
    }
  }
  .cancle-popup-wrap {
    width: 25vw;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1.5rem 1rem;
    position: relative;
    text-align: center;

    .button-box {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
    }
    .content {
      line-height: 2;
      font-size: 10.4166vw;
      margin-bottom: 1rem;
    }
    .bottom-button-box {
      button {
        background-color: #eee;
        padding: 0.8rem 1rem;
        border-radius: 0.8rem;
      }
    }
  }
}
