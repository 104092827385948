* {
  -webkit-box-sizing: boerder-box;
          box-sizing: boerder-box;
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

ul,
ol,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

button {
  display: inline-block;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1em;
}

html {
  width: 100%;
  height: 100%;
}

body {
  font-size: 1em;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
  background-color: #f3f2f7;
}

.blind {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.greet {
  color: #464255;
  font-size: 0.8333vw;
  text-align: right;
  margin-bottom: 3.854vw;
}

.greet span {
  font-weight: bold;
}

.container {
  width: 76.46vw;
  margin: 0 auto;
  height: unset;
  padding: 4.166vw 0 3.125vw;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 3.49vw;
  margin-bottom: 1.25vw;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.header .header-left {
  height: 3.49vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.header .header-left h2 {
  font-size: 1.666vw;
  color: #262626;
}

.header .header-left span {
  font-size: 0.9375vw;
  color: #a3a3a3;
}

.header .header-right button {
  width: 6.25vw;
  margin-left: 1.0416vw;
  height: 2.708vw;
  line-height: 2.708vw;
  font-size: 0.8333vw;
  background-color: #00b074;
  border-radius: 0.4166vw;
  text-align: center;
  color: #fff;
}

.header .header-right button:disabled {
  background-color: #ddd;
  color: #666;
}

.perform-content {
  width: 100%;
  height: 28.125vw;
  padding: 3.125vw 2.5vw;
  background-color: #fff;
  border-radius: 0.8333vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.perform-content .content-left {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.perform-content .content-left .option {
  height: 3.125vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.perform-content .content-left .option label.title {
  font-size: 0.8333vw;
  font-weight: 700;
  width: 4.166vw;
}

.perform-content .content-left .option input[type="text"],
.perform-content .content-left .option input[type="number"] {
  width: 21.77vw;
  height: 2.3vw;
  padding-left: 12px;
  margin: 0 1.04vw 0 0;
  border: none;
  border-bottom: 1px solid #e4e4e4;
}

.perform-content .content-left .option input[type="file"] + label {
  display: inline-block;
  border-radius: 0.4166vw;
  width: 5.208vw;
  height: 2.604vw;
  font-size: 0.8333vw;
  background-color: #00b074;
  color: #fff;
  line-height: 2.604vw;
  text-align: center;
  cursor: pointer;
}

.perform-content .content-left .option input[type="datetime-local"] {
  display: inline-block;
  max-width: 11.2vw;
}

.perform-content .content-left .option label.inner {
  margin-left: 1.04166vw;
}

.perform-content .content-right {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.perform-content .content-right .title {
  font-weight: 700;
}

.perform-content .content-right .image-preview {
  width: 100%;
  height: 19.8vw;
  background-color: #ddd;
}

.perform-content .content-right .image-preview img.preview-image {
  width: 100%;
  height: 19.8vw;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid #ddd;
}

.list-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 1.354vw 2.5vw;
  background-color: #00b074;
  border-radius: 0.625vw;
  margin-bottom: 1.25vw;
}

.list-header li {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  color: #fff;
  font-size: 0.833vw;
  line-height: 0.9896vw;
}

.list-content {
  width: 100%;
  padding: 1.875vw 2.5vw;
  background-color: #fff;
  border-radius: 0.8333vw;
}

.list-content li.list-item {
  height: 8.333vw;
  border-bottom: 1px solid #f5f5f5;
  padding: 0.8333vw 0;
  border-radius: 0.8333vw;
}

.list-content li.list-item.selected {
  background-color: #f5f5f5;
}

.list-content li.list-item ul {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.list-content li.list-item ul li {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  font-size: 0.8333vw;
}

.list-content li.list-item ul li a {
  text-decoration: underline;
}
