#root {
  .login-wrap {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;

    .login-container {
      width: 50%;
      border: 1px solid #eee;
      background-color: #fff;
      border-radius: 1.666vw;
      padding: 6vw 0;

      h1 {
        text-align: center;
        font-size: 2vw;
        margin-bottom: 2.5vw;
      }

      .login-input {
        display: block;
        width: 21.0416vw;
        height: 2.2083vw;
        padding: 0.3083vw;
        margin: 0.5208vw auto;
        background-color: #fff;
        border: 1px solid #eee;
        border-radius: 0.3083vw;
      }

      .login-btn {
        display: block;
        margin: 2vw auto;
        width: 8.33vw;
        height: 36px;
        padding: 0.2083vw;
        border: none;
        border-radius: 5px;
        background-color: lightblue;
        color: #fff;
      }
    }
  }
}
