#root {
  .report-wrap {
    flex: 1;
    background-color: #f3f2f7;

    .report-container {
      .content {
        display: flex;
        .report-list {
          flex: 2;
          margin-right: 2.604vw;
        }
      }

      .details-wrap {
        flex: 1;
        border-radius: 0.8333vw;
        position: relative;

        .item-details {
          position: fixed;
          padding: 1.0416vw;
          border-radius: 0.8333vw;
          text-align: center;
          background-color: #fff;

          .answer-button,
          .item-image-button {
            width: 8.333vw;
            height: 1.666vw;
            line-height: 0.2083vw;
            padding: 0.2083vw;
            border: none;
            border-radius: 0.3125vw;
            background-color: #00b074;
            color: #fff;
            font-size: 0.833vw;
          }

          .option {
            padding: 0.4166vw;
            margin: 0 auto 0.5208vw;

            label {
              font-weight: normal;
              display: block;
              margin-bottom: 0.8332vw;
              font-size: 0.9375vw;
            }

            .item-question,
            .item-detail-content {
              font-size: 0.833vw;
              display: block;
              margin: 0 auto;
              width: 240px;
              line-height: 1.8vw;
              height: 1.8vw;
              border: 1px solid #ddd;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
  .report-answer {
    position: absolute;
    width: 500px;
    height: 400px;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: lightblue;
    text-align: center;

    .report-content {
      height: 10.4166vw;
    }
  }
  .report-image {
    position: absolute;
    padding: 1.0416vw;
    background-color: lightblue;

    .image-area {
      img {
        width: 500px;
        height: 400px;
        object-fit: contain;
      }
    }
  }
}
