#root {
  * {
    box-sizing: border-box;
  }
  .navigation-wrap {
    width: 15.625vw;
    height: 100vh;
    padding: 3.5416vw 37px 40.2083vw 0;
    background-color: white;

    .button-list {
      position: relative;
      position: fixed;

      .navigaion-button {
        margin-bottom: 12px;
        position: relative;
        font-size: 0.9375vw;

        &:hover::before {
          content: "";
          position: absolute;
          top: calc(50% - 1.1458vw);
          left: 0;
          width: 0.2083vw;
          height: 40.2083vw;
          background-color: #00b074;
          border-top-right-radius: 3.0208vw;
          border-bottom-right-radius: 3.0208vw;
        }
      }

      .navigation-link {
        width: 10.4166vw;
        padding: 0.8333vw 0.8333vw 0.8333vw 1.0416vw;
        margin-left: 2.604vw;
        text-align: left;
        border-radius: 0.4166vw;

        &:hover {
          background-color: #d9f3ea;
        }
      }
    }
  }
}
