#root * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#root .navigation-wrap {
  width: 15.625vw;
  height: 100vh;
  padding: 3.5416vw 37px 40.2083vw 0;
  background-color: white;
}

#root .navigation-wrap .button-list {
  position: relative;
  position: fixed;
}

#root .navigation-wrap .button-list .navigaion-button {
  margin-bottom: 12px;
  position: relative;
  font-size: 0.9375vw;
}

#root .navigation-wrap .button-list .navigaion-button:hover::before {
  content: "";
  position: absolute;
  top: calc(50% - 1.1458vw);
  left: 0;
  width: 0.2083vw;
  height: 40.2083vw;
  background-color: #00b074;
  border-top-right-radius: 3.0208vw;
  border-bottom-right-radius: 3.0208vw;
}

#root .navigation-wrap .button-list .navigation-link {
  width: 10.4166vw;
  padding: 0.8333vw 0.8333vw 0.8333vw 1.0416vw;
  margin-left: 2.604vw;
  text-align: left;
  border-radius: 0.4166vw;
}

#root .navigation-wrap .button-list .navigation-link:hover {
  background-color: #d9f3ea;
}
